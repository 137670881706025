define("discourse/plugins/discourse-presence/discourse/lib/presence", ["exports", "@ember/runloop", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _runloop, _object, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TOPIC_TYPE = _exports.REPLYING = _exports.KEEP_ALIVE_DURATION_SECONDS = _exports.EDITING = _exports.COMPOSER_TYPE = _exports.CLOSED = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  // The durations chosen here determines the accuracy of the presence feature and
  // is tied closely with the server side implementation. Decreasing the duration
  // to increase the accuracy will come at the expense of having to more network
  // calls to publish the client's state.
  //
  // Logic walk through of our heuristic implementation:
  // - When client A is typing, a message is published every KEEP_ALIVE_DURATION_SECONDS.
  // - Client B receives the message and stores each user in an array and marks
  //   the user with a client-side timestamp of when the user was seen.
  // - If client A continues to type, client B will continue to receive messages to
  //   update the client-side timestamp of when client A was last seen.
  // - If client A disconnects or becomes inactive, the state of client A will be
  //   cleaned up on client B by a scheduler that runs every TIMER_INTERVAL_MILLISECONDS
  const KEEP_ALIVE_DURATION_SECONDS = _exports.KEEP_ALIVE_DURATION_SECONDS = 10;
  const BUFFER_DURATION_SECONDS = KEEP_ALIVE_DURATION_SECONDS + 2;
  const MESSAGE_BUS_LAST_ID = 0;
  const TIMER_INTERVAL_MILLISECONDS = 2000;
  const REPLYING = _exports.REPLYING = "replying";
  const EDITING = _exports.EDITING = "editing";
  const CLOSED = _exports.CLOSED = "closed";
  const TOPIC_TYPE = _exports.TOPIC_TYPE = "topic";
  const COMPOSER_TYPE = _exports.COMPOSER_TYPE = "composer";
  const Presence = _object.default.extend((_dec = (0, _decorators.default)("topicId"), (_obj = {
    users: null,
    editingUsers: null,
    subscribers: null,
    topicId: null,
    currentUser: null,
    messageBus: null,
    siteSettings: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        users: [],
        editingUsers: [],
        subscribers: new Set()
      });
    },
    subscribe(type) {
      if (this.subscribers.size === 0) {
        this.messageBus.subscribe(this.channel, message => {
          const {
            user,
            state
          } = message;
          if (this.get("currentUser.id") === user.id) {
            return;
          }
          switch (state) {
            case REPLYING:
              this._appendUser(this.users, user);
              break;
            case EDITING:
              this._appendUser(this.editingUsers, user, {
                post_id: parseInt(message.post_id, 10)
              });
              break;
            case CLOSED:
              this._removeUser(user);
              break;
          }
        }, MESSAGE_BUS_LAST_ID);
      }
      this.subscribers.add(type);
    },
    unsubscribe(type) {
      this.subscribers.delete(type);
      const noSubscribers = this.subscribers.size === 0;
      if (noSubscribers) {
        this.messageBus.unsubscribe(this.channel);
        this._stopTimer();
        this.setProperties({
          users: [],
          editingUsers: []
        });
      }
      return noSubscribers;
    },
    channel(topicId) {
      return `/presence/${topicId}`;
    },
    publish(state, whisper, postId, staffOnly) {
      // NOTE: `user_option` is the correct place to get this value from, but
      //       it may not have been set yet. It will always have been set directly
      //       on the currentUser, via the preloaded_json payload.
      // TODO: Remove this when preloaded_json is refactored.
      let hiddenProfile = this.get("currentUser.user_option.hide_profile_and_presence");
      if (hiddenProfile === undefined) {
        hiddenProfile = this.get("currentUser.hide_profile_and_presence");
      }
      if (hiddenProfile && this.get("siteSettings.allow_users_to_hide_profile")) {
        return;
      }
      const data = {
        state,
        topic_id: this.topicId
      };
      if (whisper) {
        data.is_whisper = true;
      }
      if (postId && state === EDITING) {
        data.post_id = postId;
      }
      if (staffOnly) {
        data.staff_only = true;
      }
      return (0, _ajax.ajax)("/presence/publish", {
        type: "POST",
        data
      });
    },
    _removeUser(user) {
      [this.users, this.editingUsers].forEach(users => {
        const existingUser = users.findBy("id", user.id);
        if (existingUser) {
          users.removeObject(existingUser);
        }
      });
    },
    _cleanUpUsers() {
      [this.users, this.editingUsers].forEach(users => {
        const staleUsers = [];
        users.forEach(user => {
          if (user.last_seen <= Date.now() - BUFFER_DURATION_SECONDS * 1000) {
            staleUsers.push(user);
          }
        });
        users.removeObjects(staleUsers);
      });
      return this.users.length === 0 && this.editingUsers.length === 0;
    },
    _appendUser(users, user, attrs) {
      let existingUser;
      let usersLength = 0;
      users.forEach(u => {
        if (u.id === user.id) {
          existingUser = u;
        }
        if (attrs && attrs.post_id) {
          if (u.post_id === attrs.post_id) {
            usersLength++;
          }
        } else {
          usersLength++;
        }
      });
      const props = attrs || {};
      props.last_seen = Date.now();
      if (existingUser) {
        existingUser.setProperties(props);
      } else {
        const limit = this.get("siteSettings.presence_max_users_shown");
        if (usersLength < limit) {
          users.pushObject(_object.default.create(Object.assign(user, props)));
        }
      }
      this._startTimer(() => {
        this._cleanUpUsers();
      });
    },
    _scheduleTimer(callback) {
      return (0, _runloop.later)(this, () => {
        const stop = callback();
        if (!stop) {
          this.set("_timer", this._scheduleTimer(callback));
        }
      }, TIMER_INTERVAL_MILLISECONDS);
    },
    _stopTimer() {
      (0, _runloop.cancel)(this._timer);
    },
    _startTimer(callback) {
      if (!this._timer) {
        this.set("_timer", this._scheduleTimer(callback));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "channel", [_dec], Object.getOwnPropertyDescriptor(_obj, "channel"), _obj)), _obj)));
  var _default = _exports.default = Presence;
});